import { ColorObject } from '@/lib/schema/build/types';
import { FC } from 'react';

const ColorCard: FC<{
  color: ColorObject;
  handleOnClick: (color: ColorObject) => void;
  selected: boolean;
}> = ({ color, handleOnClick, selected }) => {
  const { colorName, msrp } = color;
  return (
    <div
      className={`inline-flex h-20 w-full cursor-pointer flex-col items-center justify-center gap-3 rounded border-2 p-5 ${
        selected
          ? 'border-black bg-background-light'
          : 'border-gray-300 bg-background-white'
      }`}
      onClick={() => {
        handleOnClick(color);
      }}
    >
      <div className="inline-flex items-start justify-start gap-5 self-stretch">
        <div className="Title shrink grow basis-0 text-[16px] leading-snug tracking-tight text-neutral-800">
          {colorName}
        </div>
        <div className="text-right text-base font-light leading-snug tracking-tight text-neutral-800">
          {msrp === 0 ? 'Included' : `$${msrp.toLocaleString()}`}
        </div>
      </div>
    </div>
  );
};

export default ColorCard;
