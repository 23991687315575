import React, { useEffect, useRef, useState } from 'react';
import TextButton from '@/components/atoms/legacy/TextButton';
import { Component } from '@/types/interfaces';

export interface ClampedTextProps extends Component {
  clampLines?: 1 | 2 | 3 | 4 | 5 | 6;
  expandable?: boolean;
}

const lineClampClassNames = [
  '',
  'line-clamp-1',
  'line-clamp-2',
  'line-clamp-3',
  'line-clamp-4',
  'line-clamp-5',
  'line-clamp-6',
];

const ClampedText: React.FC<ClampedTextProps> = ({
  className = '',
  clampLines = 3,
  expandable = false,
  children,
}) => {
  const [clamped, setClamped] = useState(true);
  const [isActuallyClamped, setIsActuallyClamped] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  const handleOnClick = () => {
    setClamped((prev) => !prev);
  };

  useEffect(() => {
    if (textRef.current) {
      const actualHeight = textRef.current.clientHeight;
      const fullHeight = textRef.current.scrollHeight;
      setIsActuallyClamped(fullHeight > actualHeight);
    }
  }, [textRef]);

  return (
    <>
      <p
        className={`${
          clamped ? lineClampClassNames[clampLines] : ''
        } ${className}`}
        ref={textRef}
      >
        {children}
      </p>

      {expandable && isActuallyClamped && (
        <TextButton
          className="text-bodyBold4 text-blue-medium"
          onClick={handleOnClick}
        >
          {clamped ? 'Read More' : 'Hide'}
        </TextButton>
      )}
    </>
  );
};

export default ClampedText;
